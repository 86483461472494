.react-datepicker {
  border: 1px solid #e0e5ee;
  border-radius: 4px;
}

.react-datepicker-wrapper {
  display: flex;
  flex-grow: 1;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  background-color: white;
  border: none;
  padding: 0;
}

.react-datepicker__header select {
  border-color: #e0e5ee;
  border-radius: 4px;
  color: #48a6c9;
  outline: none;
  padding: 4px;
  padding-left: 0;
}

.react-datepicker__day-name {
  color: #12246c;
  font-weight: 500;
}

.react-datepicker__day:hover {
  border-radius: 50%;
}

.react-datepicker__day[aria-disabled='true'] {
  color: #e0e5ee;
}

.react-datepicker__day[aria-disabled='false']:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--selected) {
  color: #12246c;
}

.react-datepicker__day[aria-disabled='false']:not(.react-datepicker__day--keyboard-selected):not(.react-datepicker__day--selected):hover {
  background-color: #e0e5ee;
}

.react-datepicker__day:focus {
  outline: none;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  background-color: #48a6c9;
  border-radius: 50%;
  color: white;
  outline: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
  font-weight: 600;
}
