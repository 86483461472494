.tippy-box {
  outline: none;
}

.tippy-content {
  padding: 0;
  outline: none;
}

.tippy-arrow {
  color: white;
}

.place-top::before {
  height: 0 !important;
}
